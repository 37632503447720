import React from "react"
import styles from "./container.module.scss"
import Header from "./header/header"
import Footer from "./footer/footer"

export default ({ children }) => (
  <div className={styles.okkContainer}>
    <Header/>

    <main>
      {children}
    </main>

    <Footer/>
  </div>
)
