import React from "react"
import styles from "./article-preview.module.scss"
import { Link } from "gatsby"

export default (props) => {
  const post = props.post

  return (
    <Link className={styles.previewArticleLink} to={post.fields.slug}>
      <h5 className={styles.previewArticleDate}>{post.frontmatter.date}</h5>
      <h3 className={styles.previewArticleTitle}>
        {post.frontmatter.title}
      </h3>
      <p className={styles.previewArticleExcerpt}>{post.frontmatter.excerpt || post.excerpt}</p>
    </Link>
  )
}
