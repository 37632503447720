import React from "react"
import styles from "./header.module.scss"
import Logo from "./logo"
import Navigation from "./navigation"
import ThemeToggler from "./theme-toggler"

export default () => (
  <header className={styles.headerSection}>
    <div className={styles.headerTopRow}>
      <Logo/>
      <ThemeToggler/>
    </div>

    <Navigation/>
  </header>
)
