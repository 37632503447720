import React from "react"

import styles from "./theme-toggler.module.scss"

import ToggleIcon from "../images/toggle-theme.svg"

const toggleTheme = (event) => {
  if (event.target.checked) {
    document.documentElement.setAttribute("data-theme", "dark")

    if (typeof window !== `undefined`) {
      localStorage.setItem("theme", "dark")
    }
  } else {
    document.documentElement.setAttribute("data-theme", "light")

    if (typeof window !== `undefined`) {
      localStorage.setItem("theme", "light")
    }
  }
}

export default () => {
  let currentTheme = null

  if (typeof window !== `undefined`) {
    currentTheme = localStorage.getItem("theme") || null
  }

  if (typeof window !== `undefined` && currentTheme == null) {
    currentTheme = getComputedStyle(document.documentElement)
      .getPropertyValue("content")
      .replace(/['"]+/g, "")
  }

  if (currentTheme) {
    document.documentElement.setAttribute("data-theme", currentTheme)

    if (currentTheme === "dark") {
      return (
        <label htmlFor="toggler">
          <input className={styles.toggleCheckbox} type="checkbox" defaultChecked={true} onChange={toggleTheme}
                 id="toggler"/>
          <img className={styles.toggleIcon} src={ToggleIcon} alt="Toggle light/dark theme"/>
        </label>
      )
    }
  }

  return (
    <label htmlFor="toggler" title="Toggle theme">
      <input className={styles.toggleCheckbox} type="checkbox" defaultChecked={false} onChange={toggleTheme}
             id="toggler"/>
      <img className={styles.toggleIcon} src={ToggleIcon} alt="Toggle light/dark theme"/>
    </label>
  )
}
