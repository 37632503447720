import React from "react"
import { Link } from "gatsby"
import styles from "./logo.module.scss"

import LogoText from "../images/logo-text.svg"

export default () => (
  <div className={styles.logoContainer}>
    <Link to={"/"}>
      <img src={LogoText} alt="OkKotlin" className={styles.logo}/>
    </Link>
  </div>
)
