import React from "react"
import styles from "./navigation.module.scss"
import { Link } from "gatsby"

import HomeIcon from "../images/nav-home.svg"
import ArticlesIcon from "../images/nav-articles.svg"
//import QuickTipsIcon from "../images/nav-tips.svg"
import NewsletterIcon from "../images/nav-newsletter.svg"

export default () => (
  <nav className={styles.navigationContainer}>
    <ul>
      <li>
        <Link to="/" activeStyle={{ color: "#f98e43" }}>
          <img src={HomeIcon} alt="Home"/>
          Home
        </Link>
      </li>
      <li>
        <Link to="/articles" activeStyle={{ color: "#f98e43" }}>
          <img src={ArticlesIcon} alt="Articles"/>
          Articles
        </Link>
      </li>
      {/*<li>*/}
      {/*  <img src={QuickTipsIcon} alt="Quick Tips"/>*/}
      {/*  Quick Tips*/}
      {/*</li>*/}
      <li>
        <Link to="/newsletter" activeStyle={{ color: "#f98e43" }}>
          <img src={NewsletterIcon} alt="Newsletter"/>
          Newsletter
        </Link>
      </li>
    </ul>
  </nav>
)
