import React from "react"
import styles from "./footer.module.scss"

import TwitterIcon from "../images/link-twitter.svg"
import RssIcon from "../images/link-rss.svg"
import EmailIcon from "../images/link-email.svg"

export default () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className={styles.footerSection}>
      <p className={styles.footerText}>Copyright © {currentYear} Typemojo LLP. &nbsp; OkKotlin is brought to you by
        <a
          className={styles.footerTextLink}
          href="https://twitter.com/chowdhuryrahul"
          target="_blank"
          rel="noreferrer noopener nofollow"
        >
          @chowdhuryrahul
        </a>.
      </p>

      <div className={styles.footerLinks}>
        <a
          href="https://twitter.com/okkotlin"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <img className={styles.footerLinkItem} src={TwitterIcon} alt="OkKotlin on Twitter"/>
        </a>
        <a
          href="/rss.xml"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <img className={styles.footerLinkItem} src={RssIcon} alt="Subscribe to OkKotlin's Feed"/>
        </a>
        <a href="mailto:hi@okkotlin.com"> <img className={styles.footerLinkItem} src={EmailIcon} alt="Email OkKotlin"/></a>
      </div>
    </footer>
  )
}
